import WithdrawProfitRow from "../WithdrawProfit/WithdrawProfitRow";
import TableData from "./TableData";
const Table = ({
  data,
  titles,
  pagination,
}) => {
  return (
    <table>
      <thead>
        {titles.map((title) => {
          return <th>{title}</th>;
        })}
      </thead>

      {data?.length !== 0 ? (
        <tbody>
          {data?.map((market, index) => {
            return (
              <tr>
                {market.data.map((item) => {
                  return (
                    <TableData
                      index={index}
                      market={market}
                      item={item}
                      pagination={pagination}                                      
                    />
                  );
                })}
              </tr>
            );
          })}
          {/* </tr> */}
        </tbody>
      ) : null}
    </table>
  );
};

export default Table;
