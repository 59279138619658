import { Box, Button } from "@mui/material";
// import { checkMarketBalance } from "../../../contracts/marketPlace";
import { useEffect, useState } from "react";
import { getTotalMarketCount } from "../../../contracts/marketPlace";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const WithdrawProfitRow = (props) => {
  const [totalMarketBets, setTotalMarketBets] = useState(0)
  const {
    market,
    index,
    setMarketModalData,
    setIsOpenResolveModal,
    marketProfit,
    pagination
    // fetchMarketData,
  } = props;

const {recordsPerPage,currentPage}=pagination
   
  useEffect(() => {
    // fetchMarketData();
  getMarketTotalBets()
  }, [market?.adminWithdraw]);

  const getMarketTotalBets = async() => {
    const totalBets = await getTotalMarketCount(market?.factoryContractAddress)
  //  console.log('totalbest ====>', totalBets)
    setTotalMarketBets(totalBets)
  }

  // const isProfitAvailable = async () => {
  //   const total = await checkMarketBalance(market.factoryContractAddress);
  //   if (total == 0) {
  //     setHideProfitBtn(true);
  //   } 
  // };
  // console.log(market)
  return (
    <tr key={index}>
      <td>
        <span>{index + 1 + (currentPage - 1) * recordsPerPage}</span>
      </td>
      <td>
        <span>{market?.typeName}</span>
      </td>
      <td>
        <span>{market?.category}</span>
      </td>
      <td>
        <span>{market?.question}</span>
      </td>
      <td>
        <span>10%</span>
        {/* <span>
            {market?.active ? (
              <img
                src={DoneIcon}
                alt="paid-icon"
                className="dashboard-content-icon"
              />
            ) : (
              <img
                src={CancelIcon}
                alt="canceled-icon"
                className="dashboard-content-icon"
              />
            )}
          </span> */}
      </td>
      <td>
        <span>{totalMarketBets || 0}</span>
        {/* <span>
            {market?.archived ? (
              <img
                src={DoneIcon}
                alt="paid-icon"
                className="dashboard-content-icon"
              />
            ) : (
              <img
                src={CancelIcon}
                alt="canceled-icon"
                className="dashboard-content-icon"
              />
            )}
          </span> */}
      </td>
      <td>
        <div>
          <img
            src={market?.image}
            className="dashboard-content-avatar"
            alt="Market Picture"
          />
        </div>
      </td>
      <td>
        <span>{market.winningOutCome}</span>
        {/* <span>
            {market.closed ? (
              <img
                src={DoneIcon}
                alt="paid-icon"
                className="dashboard-content-icon"
              />
            ) : (
              <img
                src={CancelIcon}
                alt="canceled-icon"
                className="dashboard-content-icon"
              />
            )}
          </span> */}
      </td>

        <td>
          <Box className="actions">
            <div className="d-flex gap-10">

              {/* {market.status === 'expired' && (
                <>

                  <Button
                    variant="contained"
                    data-id={market?._id}
                    size="small"
                    onClick={() => {
                      setMarketModalData(market);
                      setIsOpenResolveModal(true);
                      // handleResolveMarket(1, market.factoryContractAddress)
                    }}
                  >
                    Resolve
                  </Button>
                </>
              )} */}
              {/* {market?.adminWithdraw === false && (market?.bets?.some((bet) => bet.winner === false)) === true ? (
                <Button
                  onClick={() => marketProfit(market.factoryContractAddress, market._id)}
                  className="success-btn"
                  size="small"
                >
                  Profit
                </Button>
              ) : market?.adminWithdraw === true ? <p className="profit-text">Claimed</p> : <></>} */}
            </div>
          </Box>
          {/* <button>Edit</button>
        <button className="delete-btn">Delete</button> */}
        </td>
    </tr>
  );
};

export default WithdrawProfitRow;
