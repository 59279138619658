import React, { useState, useEffect, useContext } from "react";
import DashboardHeader from "../../components/DashboardHeader";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import UpdateModal from "../../components/Modal/UpdateModal";
import CreateModal from "../../components/Modal/CreateModal";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAxios, postAxios } from "../../utils/api";
import ResolveModal from "../../components/Modal/ResolveModal";
import { resolveMarket } from "../../contracts/marketPlace/index";
import { useSelector, useDispatch } from "react-redux";
import { addTransaction } from "../../redux/actions/transaction";
import SocketContext from "../../context/socket";
import WithdrawProfitRow from "../../components/WithdrawProfit/WithdrawProfitRow";
import { getAllCategories } from "../../redux/actions/categories";
import TableData from "../../components/Table";
import Fade from "@mui/material/Fade";
import { KeyboardArrowDown } from "@mui/icons-material";
function Orders() {
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState(all_orders);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [marketData, setMarketData] = useState();
  const [isOpenResolveModal, setIsOpenResolveModal] = useState(false);
  const [marketModalData, setMarketModalData] = useState(null);
  const socket = useContext(SocketContext);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [slicedData, setSlicedData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [listFilter,setListFilter]=useState({status:"created"})
  const [activeMenu,setActiveMenu]=useState("All")
  const sliceDataForCurrentPage = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    return marketList?.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const sliced = sliceDataForCurrentPage();
    // setSlicedData(sliced);
    formatData(sliced);
  }, [currentPage, marketList, recordsPerPage]);

  useEffect(() => {
    // setPagination(calculateRange(marketList, recordsPerPage));
    fetchMarketList();
   
  }, [listFilter]);
  useEffect(()=>{
    dispatch(getAllCategories());
  },[])

  useEffect(() => {
    setPagination(calculateRange(marketList, recordsPerPage));
  }, [marketList]);
  const formatItem = (item, i) => {
    const { typeName, category, question, image ,outcomes,totalBets} =
      item;
    return [
      <span>{i + 1}</span>,
      <span>{typeName}</span>,
      <span>{category}</span>,
      <span>{question}</span>,
      <span>{"10%"}</span>,
      <span>${totalBets}</span>,

      <img
        src={image}
        className="dashboard-content-avatar"
        alt="Market Picture"
      />,
      <span>{`${outcomes[0]?.outcome1},${outcomes[1]?.outcome1}`}</span>,
      // <span>{winningOutCome}</span>,
      <Button
        variant="contained"
        // data-id={market?._id}
        size="small"
        onClick={() => {
          setOpen(true);
          setMarketModalData(item);
          // setIsOpenResolveModal(true);
          // handleResolveMarket(1, market.factoryContractAddress)
        }}
      >
        Edit
      </Button>,
    ];
  };

  const menuListOpen = Boolean(anchorEl);
  const handleOpeneMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const formatData = (data) => {
    const updatedData = data.map((item, i) => {
      return {
        data: formatItem(item, i),
      };
    });
    setSlicedData(updatedData);
  };


  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEditMarketClickOpen = () => {
    setEditOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    fetchMarketList();
  };
  const handleEditMarketClose = () => {
    setEditOpen(false);
    fetchMarketList();
  };

  // useEffect(() => {
  //   setPagination(calculateRange(marketList, 5));
  //   // setMarketList(sliceData(marketList, page, 5));
  //   fetchMarketList();
  // }, []);

  const fetchMarketList = async () => {
    const data = {
      url: "market/fetch_market_list",
      payload: {...listFilter},
    };
    await getAxios(data).then((response) => {
      if (response?.data?.error) {
        setMarketList([]);
      } else {
        const sortedMarkets = response?.data?.data?.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          // Sorting in descending order
          return dateB - dateA;
        });


        const updatedMarket = sortedMarkets?.map((market) => {
          if (market?.bets?.length > 0) {
            const totalBets = market?.bets?.reduce(
              (accumulator, currentValue) => accumulator + currentValue.amount,
              0
            );
            return { ...market, totalAmountOfBets: totalBets };
            // console.log('updateMarket ====>', updateMarket)
          }
          return market;
        });

        setMarketList(updatedMarket);
      }
    });
  };

  const marketDelete = async (e) => {
    const marketId = e.target.getAttribute("data-id");
    const data = {
      url: "market/delete_market",
      payload: {
        id: marketId,
      },
    };
    await postAxios(data).then((response) => {
      if (response?.data?.error) {
      } else {
        fetchMarketList();
      }
    });
  };

  const editMarket = async (e) => {
    const marketId = e.target.getAttribute("data-id");
    const data = {
      url: "market/fetch_market",
      payload: {
        id: marketId,
      },
    };
    await postAxios(data).then((response) => {
      if (response?.data?.error) {
      } else {
        setEditOpen(true);
        setMarketData(response?.data?.data);
      }
    });
  };
  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== ""&&event.target.value?.length>=3) {
      let search_results = marketList?.filter(
        (item) =>
          item?.question?.toLowerCase().includes(search.toLowerCase()) ||
          item?.slug?.toLowerCase().includes(search.toLowerCase()) ||
          item?.category?.toLowerCase().includes(search.toLowerCase())
      );
      formatData(search_results);
      // setSlicedData(search_results);
    } 
    if(event.target.value==='') {
      resetSlicedData();
    }
  };

  const resetSlicedData = () => {
    formatData(sliceDataForCurrentPage());
    // setSlicedData();
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setCurrentPage(new_page);
  };

  const menuItems=[
    {
      item:"All",
      onClick:()=>setListFilter({status:"created"})
    },
    {
      item:"Opened",
      onClick:()=>setListFilter({open:true})
    },
    {
      item:"Opening Soon",
      onClick:()=>setListFilter({openingSoon:true})
    }
  ]

  return (
    <div className="dashboard-content">
      {/* <Modal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <CreateModal
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        data={marketModalData}
        notification={(message) => toast.info(message)}
        refetchData={fetchMarketList}
      />
      <UpdateModal
        data={marketData}
        open={editOpen}
        handleClickOpen={handleEditMarketClickOpen}
        handleClose={handleEditMarketClose}
        notification={(message) => toast.info(message)}
      />
      <DashboardHeader btnText="Create New" onClick={handleClickOpen} />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Wagers List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search with category or question"
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
            <div>
              <Button
                variant="contained"
                id="fade-button"
                aria-controls={menuListOpen ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuListOpen ? "true" : undefined}
                onClick={handleOpeneMenu}
                endIcon={<KeyboardArrowDown />}
                sx={{ paddingBottom: "10px", paddingTop: "10px" }}
              >
                {activeMenu}
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={menuListOpen}
                onClose={handleCloseMenu}
                TransitionComponent={Fade}
              >
                {
                  menuItems?.map((menu)=>{
                    return (
                      <MenuItem onClick={()=>{
                        menu.onClick()
                        setAnchorEl(false)
                        setActiveMenu(menu.item)
                      }}>{menu.item}</MenuItem>
                    )
                  })
                }
              </Menu>
            </div>
          </div>
        </div>
        <TableData
          titles={[
            "ID",
            "TYPE NAME",
            "CATEGORY",
            "QUESTION",
            "PROFIT%",
            "MARKET TOTAL",
            "IMAGE",
            "OUTCOMES",
            "ACTIONS",
          ]}
          data={slicedData}
          pagination={{ currentPage, recordsPerPage }}
          setMarketModalData={setMarketModalData}
          setIsOpenResolveModal={setIsOpenResolveModal}
        />

        {slicedData?.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item, index) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Orders;
