import React, { forwardRef, useState,useEffect,useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Slide,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import "./ResolveModal.css";
import SocketContext from "../../context/socket";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ResolveModal = (props) => {
  const {
    openEmail,
    closeEmailModal,
    data: { question, image, factoryContractAddress, _id,outcomes,txHash },
    handleResolveMarket,
    setIsResolveMarketLoading,
    isResolveMarketLoading,
  } = props;

  const [checked, setChecked] = useState(false);
  const socket = useContext(SocketContext);
  const user = JSON.parse(localStorage.getItem("userDetail"));
  useEffect(() => {
    if (socket) {
      console.log('joining socket',user?._id)
      socket.emit("join", `${user?._id}`);
      const handleMarketResolvedEvent = () => {
        setIsResolveMarketLoading(false)
      }
      socket.on("MarketResolved",handleMarketResolvedEvent );
      return () => {
        socket?.off("MarketResolved", handleMarketResolvedEvent);
        socket.emit("leave", `${user?._id}`);
      };
    }
  }, [socket]);
  return (
    <>
      <Dialog
        className="email-modal"
        open={openEmail}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeEmailModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="emailModal_Content">
          <Box className="content_wrapper">
            <Box className="icon_Email">
              {/* <MailOutlineIcon /> */}
              <img src={image} alt="market_image" />
              <Typography variant="h3">{question}</Typography>
            </Box>
            <Box className="security_Code">
              <Typography variant="body2">
                Please select one of the options{" "}
                <span style={{ fontWeight: "700" }}>{`${outcomes[0]?.outcome1} or ${outcomes[1]?.outcome1}`}</span>
              </Typography>
              <Box className="copy_Code">
                {/* <Button className="code">Yes</Button>
                <Button className="copy">No</Button> */}
                <FormControl className="formControl_Wrapper">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    className="radio_Wrapper"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label={outcomes[0]?.outcome1 || ''}
                      onClick={() => setChecked(outcomes[0])}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label={outcomes[1]?.outcome1 || ''}
                      onClick={() => setChecked(outcomes[1])}
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  className="code"
                  disabled={
                   !checked || isResolveMarketLoading
                  }
                  onClick={() =>
                    handleResolveMarket(checked, factoryContractAddress, _id,txHash)
                  }
                >
                  {isResolveMarketLoading ? "Resolving" : "Resolve"}
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResolveModal;
