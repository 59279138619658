import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Logo from './assets/images/light-logo.svg';
import './App.css';
import Socket from './utils/socket';
import SocketContext from './context/socket';
import { routes } from './routes';
import PrivateRoute from './privateRoute';

function App () {
  const [socket, setSocket] = useState(undefined);
  useEffect(() => {
    if (!socket) {
      const connectedSocket = Socket.connectSocket();
      setSocket(connectedSocket);
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SocketContext.Provider value={socket}>
      <Router>
        {
          <>
            <Routes>
              {routes.map((route, ind) => {
                return (
                  <Route
                    key={`route_${ind}`}
                    path={route.path}
                    element={
                      <PrivateRoute access={route.access}>
                        <route.layout>
                          {' '}
                          <route.component/>
                        </route.layout>
                      </PrivateRoute>
                    }
                  />
                );
              })}
            </Routes>
          </>

          // <div className="dashboard-container">
          //   <SideBar menu={sidebar_menu} />

          //   <div className="dashboard-body">
          //     <Routes>
          //       <Route path="*" element={<div></div>} />
          //       <Route exact path="/" element={<div></div>} />
          //       <Route exact path="/market" element={<Orders />} />
          //       <Route
          //         exact
          //         path="/announce-winner"
          //         element={<AnnounceWinner />}
          //       />
          //       <Route
          //         exact
          //         path="/liquidity"
          //         element={<DistributeLiquidity />}
          //       />
          //       <Route exact path="/locations" element={<div></div>} />
          //       <Route exact path="/profile" element={<div></div>} />
          //     </Routes>
          //   </div>
          // </div>
          // )
        }
      </Router>
    </SocketContext.Provider>
  );
}

const logoElement = document.querySelector('link[rel="icon"]');
if (logoElement) {
  logoElement.href = Logo;
}
export default App;
