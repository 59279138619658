
// import { checkMarketBalance } from "../../../contracts/marketPlace";
import { useEffect, useState } from "react";

const TableData = (props) => {
  const [totalMarketBets, setTotalMarketBets] = useState(0)
  const {
    pagination,
    item
  } = props;

const {recordsPerPage,currentPage}=pagination
   
  // useEffect(() => {
    // fetchMarketData();
  // getMarketTotalBets()
  // }, [market?.adminWithdraw]);

  // const getMarketTotalBets = async() => {
  //   const totalBets = await getTotalMarketCount(market?.factoryContractAddress)
  // //  console.log('totalbest ====>', totalBets)
  //   setTotalMarketBets(totalBets)
  // }
  return (
    <>  
       <td>
       {item}
      </td> 
    </>
  );
};

export default TableData;
