import CampaignIcon from '@mui/icons-material/CampaignOutlined';
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ImagesearchRollerOutlinedIcon from '@mui/icons-material/ImagesearchRollerOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import ResolvedWagers from '../pages/ResolvedWagers';

const sidebar_menu = [
  {
    id: 1,
    icon: <TrendingUpIcon />,
    path: "/market",
    title: "Wagers",
  },
  {
    id: 2,
    icon: <CampaignIcon />,
    path: "/announce-winner",
    title: "Annouce Winner",
  },
  {
    id: 3,
    icon: <CampaignIcon />,
    path: "/ended-wagers",
    title: "Ended Wagers",
  },

  {
    id: 4,
    icon: <PermIdentityIcon />,
    path: "/profile",
    title: "My account",
  },
  {
    id: 5,
    icon: <CategoryIcon />,
    path: "/category",
    title: "Category",
  },
  {
    id: 6,
    icon: <ImagesearchRollerOutlinedIcon />,
    path: "/banner",
    title: "Banner",
  },
  
];

export default sidebar_menu;
