import HomeLayout from '../layouts/HomeLayout';
import LoginLayout from '../layouts/LoginLayout';
import Orders from '../pages/Orders';
import AnnounceWinner from '../pages/AnnounceWinner';
import DistributeLiquidity from '../pages/DistributeLiquidity';
import Login from '../components/Login/index';
import Profile from '../pages/Profile';
import WithdrawProfit from '../pages/WithdrawProfit';
import Settings from '../pages/Settings';
import Categories from '../pages/Categories';
import EndedWagers from '../pages/ResolvedWagers';

export const routes = [
  {
    path: '/login',
    access: true,
    exact: false,
    title: 'login',
    layout: LoginLayout,
    component: Login,
  },
  {
    path: '/market',
    access: false,
    exact: true,
    title: 'markets',
    layout: HomeLayout,
    component: Orders,
  },
  {
    path: '/',
    access: false,
    exact: true,
    title: 'markets',
    layout: HomeLayout,
    component: Orders,
  },
  {
    path: '/announce-winner',
    access: false,
    exact: true,
    title: 'annnounce-winner',
    layout: HomeLayout,
    component: AnnounceWinner,
  },
  {
    path: '/ended-wagers',
    access: false,
    exact: true,
    title: 'ended-wagers',
    layout: HomeLayout,
    component: EndedWagers,
  },
  {
    path: '/withdraw-profit',
    access: false,
    exact: true,
    title: 'withdraw-profit',
    layout: HomeLayout,
    component: WithdrawProfit,
  },
  {
    path: '/banner',
    access: false,
    exact: true,
    title: 'banner-settings',
    layout: HomeLayout,
    component: Settings,
  },
  {
    path: '/category',
    access: false,
    exact: true,
    title: 'category',
    layout: HomeLayout,
    component: Categories,
  },
  // {
  //   path: "/liquidity",
  //   access: false,
  //   exact: true,
  //   title: "liquidity",
  //   layout: HomeLayout,
  //   component: DistributeLiquidity,
  // },

  {
    path: '/profile',
    access: false,
    exact: true,
    title: 'profile',
    layout: HomeLayout,
    component: Profile,
  },
];
