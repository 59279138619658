import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { TextareaAutosize } from "@mui/material";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMarket } from "../../contracts/createMarket/index";
import {
  isMetaMaskInstalled,
  validateMetamask,
  redirectToMetaMaskExtension,
} from "../../contracts/web3";
import "./styles.css";
import { postAxios } from "../../utils/api";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SocketContext from "../../context/socket";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
const CreateModal = ({
  open,
  handleClose,
  notification,
  fetchMarketList,
  data,
  setOpen,
  refetchData,
}) => {
  const theme = useTheme();
  const [formErrors, setFormErrors] = useState({});
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [market, setMarket] = useState({
    typeName: data?.typeName || "",
    category: "",
    question: "",
    startDate: data?.startData || null,
    endDate: data?.endDate || null,
    image: "",
    outcome1: data?.outcomes?.length ? data?.outcomes[0].outcome1 : "",
    outcome2: data?.outcomes?.length ? data?.outcomes[1].outcome2 : "",
    description: "",
  });

  // const [slug, setSlug] = useState('');
  const [active, setActive] = useState(Boolean);
  const [closed, setClosed] = useState(Boolean);
  const [archived, setArchived] = useState(Boolean);
  const [endDate, setEndDate] = useState(null);
  const [personName, setPersonName] = React.useState([]);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const socket = useContext(SocketContext);
  console.log(socket, "socket=>");
  const { categories } = useSelector((state) => state.category);
  const handleReset = () => {
    setMarket({
      typeName: "",
      category: "",
      question: "",
      endDate: null,
      image: "",
      outcome1: null,
      outcome2: null,
    });
  };
  useEffect(() => {
    if (data) {
      setMarket({
        typeName: data?.typeName || "",
        category: data?.category || "",
        question: data?.question || "",
        startDate: moment(data?.startDate) || null,
        endDate: moment(data?.endDate) || null,
        image: data?.image || "",
        description: data?.description || "",
        outcome1: data?.outcomes?.length ? data?.outcomes[0].outcome1 : "",
        outcome2: data?.outcomes?.length ? data?.outcomes[1].outcome1 : "",
      });
    }
  }, [data]);
  useEffect(() => {
    if (socket) {
      const handleMarketCreateEvent = () => {
        toast.success("Wager Created successfully");

        setLoading(false);
        handleReset();
        handleClose();
      };
      const user = JSON.parse(localStorage.getItem("userDetail"));
      socket.emit("join", `${user?._id}`);
      socket.on("MarketCreated", handleMarketCreateEvent);
      return () => {
        socket?.off("MarketCreated", handleMarketCreateEvent);
        socket.emit("leave", `${user?._id}`);
      };
    }
  }, [socket]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const saveFile = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setFile(file);
    setMarket((prev) => ({
      ...prev,
      image: file, // Replace `newPrice` with the actual value you want to set
    }));
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const names = [
    { title: "YES", value: "yes" },
    { title: "NO", value: "no" },
  ];
  const MAX_QUESTION_LENGTH = 128;
  const submit = async (e) => {
    try {
      const errors = {};
      const { typeName, category, question, endDate, description, startDate } =
        market;
      if (!typeName) {
        errors.typeName = "Type Name is required";
      }
      if (!category) {
        errors.category = "Category is required";
      }
      if (!question) {
        errors.question = "Question is required";
      }

      if (!description) {
        errors.description = "Description is required";
      }

      // If there are errors, set them in the state
      if (Object.keys(errors)?.length > 0) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      if (isMetaMaskInstalled()) {
        if (!data) {
          const address = await validateMetamask();
          if (address) {
            await createMarket({
              endTime: new Date(endDate).getTime() / 1000,
              startTime: new Date(startDate).getTime() / 1000,
              handleCreateNewMarket,
            });
          }
        } else {
          //edit market
          await editMarket();
        }

        return;
      }

      alert("Please install metamask to continue");
      redirectToMetaMaskExtension();
    } catch (err) {
      console.log(err, "err");
      setLoading(false);
      toast.error(err?.message);
    }
  };

  const handleCreateNewMarket = async (
    txHash,
    fromAddress
    // factoryContractAddress
  ) => {
    try {
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      const formData = new FormData();
      // const startDate=
      const payload = {
        ...market,
        active: active,
        closed: closed,
        archived: archived,
        // factoryContractAddress,
        status: "pending",
        winningOutCome: null,
        txHash,
        fromAddress,
        userId: userDetail?._id,
        // image:file
      };
      for (let key in payload) {
        formData.append(key, payload[key]);
      }
      const data = {
        url: "market/store_market",
        payload: formData,
      };
      await postAxios(data).then((response) => {
        // setLoading(false);
        if (response?.data?.error) {
        } else {
        }
      });
    } catch (err) {}
  };
  const editMarket = async (e) => {
    const { typeName, category, question, slug, outcomes, description, image } =
      market;
    if (typeName === "") {
      toast.warn("Type Name Field Required");
      return false;
    }
    if (category === "") {
      toast.warn("Category Field Required");
      return false;
    }
    if (question === "") {
      toast.warn("Question Field Required");
      return false;
    }

    if (outcomes?.length === 0) {
      toast.warn("OutComes Field Required");
      return false;
    }

    if (description === "") {
      toast.warn("Description Field Required");
      return false;
    }

    const formData = new FormData();
    const formDataPayload = {
      typeName: typeName,
      category: category,
      question: question,
      // slug:slug,
      // active:active,
      // closed:closed,
      // archived:archived,
      description: description,
      // outcomes: outcomes,
      // image:image,
      image,
    };
    formData.append("id", data._id);
    for (let key in formDataPayload) {
      formData.append(key, formDataPayload[key]);
    }

    const payload = {
      url: "market/update_market",
      payload: formData,
    };
    await postAxios(payload).then((response) => {
      if (response?.data?.error) {
        toast.error(response?.data?.error);
      } else {
        setOpen(false);
        toast.success("Market ");
        setLoading(false);
        refetchData();

        // setTypeName("");
        // setCategory("");
        // setQuestion("");
        // setOutcomes([]);
        // setSlug("");
        // notification(response?.data?.message);
        // handleClose();
      }
    });
  };

  const closeModalHandle = () => {
    handleReset();
    handleClose();
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setMarket((prev) => ({
      ...prev,
      [name]: value, // Replace `newPrice` with the actual value you want to set
    }));
  };

  return (
    <React.Fragment>
      {/* <Loader loading={loading} loadingText="Creating Market Please Wait...!" /> */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        // onClose={closeModalHandle}
        className="CreateMarketDialog_Wrapper"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            // Only allow the dialog to close when the reason is not 'backdropClick'
            // You can add additional conditions if needed.
            closeModalHandle();
          }
        }}
        disableBackdropClick
      >
        <DialogTitle>Create New Wager</DialogTitle>
        <DialogContent className="marketModal_wrapper">
          <Box className="marketModal_content">
            <Box className="Type_Name">
              <InputLabel>Type Name</InputLabel>
              <input
                className="type_Input"
                id="typeName"
                name="typeName"
                value={market.typeName}
                onChange={onChange}
                variant="outlined"
                placeholder="Enter Type Name"
                onBlur={() => {
                  if (formErrors.typeName) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      typeName: "", // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.typeName && (
                <p className="error" style={{ color: "red" }}>
                  {formErrors.typeName}
                </p>
              )}
            </Box>
            <Box className="Type_Name">
              <InputLabel>Category</InputLabel>
              <Select
                className="outcome_Select"
                name="category"
                id="category"
                value={market.category}
                onChange={onChange}
                placeholder="Enter category"
              >
                {categories?.map((el) => (
                  <MenuItem
                    key={el?._id}
                    value={el?.name}
                    style={getStyles(el?.name, personName, theme)}
                  >
                    {el?.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box className="Type_Name">
              <InputLabel>Start Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    className="date-picker MuiStack-root"
                    value={market?.startDate || null}
                    // defaultValue={market?.startDate}
                    disable={data && true}
                    minDateTime={dayjs().add(10, "minute")}
                    closeOnSelect={false}
                    onChange={(value) => {
                      const selectedDate = new Date(value);
                      // Prevent setting invalid times
                      if (selectedDate < new Date()) {
                        return;
                      }
                      if (
                        market?.endDate &&
                        selectedDate >= new Date(market?.endDate)
                      ) {
                        toast.error(
                          "Start date must be earlier than the end date"
                        );
                        setMarket((prev) => ({
                          ...prev,
                          startDate: "",
                        }));
                        return;
                      }
                      setMarket((prev) => ({
                        ...prev,
                        startDate: value,
                      }));
                    }}
                    onBlur={() => {
                      if (formErrors.startDate) {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          startDate: "", // Clear the error message for 'typeName'
                        }));
                      }
                    }}
                  />
                  {formErrors.startDate && (
                    <p className="error" style={{ color: "red" }}>
                      {formErrors.startDate}
                    </p>
                  )}
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box className="Type_Name">
              <InputLabel>End Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    className="date-picker MuiStack-root"
                    value={market?.endDate}
                    defaultValue={market?.endDate}
                    disabled={data ? true : false}
                    closeOnSelect={false}
                    minDateTime={dayjs().add(20, "minute")}
                    onChange={(value) => {
                      const selectedDate = new Date(value);
                      selectedDate.setMinutes(selectedDate.getMinutes() + 20);
                      // Prevent setting invalid times
                      if (selectedDate < new Date()) {
                        return;
                      }
                      if (
                        market?.endDate &&
                        selectedDate <= new Date(market?.start)
                      ) {
                        toast.error(
                          "End date must be greater than the start date"
                        );
                        setMarket((prev) => ({
                          ...prev,
                          endDate: "",
                        }));
                        return;
                      }
                      // const converted=momentTimezone(new Date(value))
                      setMarket((prev) => ({
                        ...prev,
                        endDate: value, // Replace `newPrice` with the actual value you want to set
                      }));
                    }}
                    onBlur={() => {
                      if (formErrors.endDate) {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          endDate: "", // Clear the error message for 'typeName'
                        }));
                      }
                    }}

                    // label="End Time"
                  />
                  {formErrors.endDate && (
                    <p className="error" style={{ color: "red" }}>
                      {formErrors.endDate}
                    </p>
                  )}
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box className="Type_Name">
              <InputLabel>Question</InputLabel>
              <input
                className="type_Input"
                name="question"
                id="question"
                value={market.question}
                onChange={onChange}
                placeholder="Enter question"
                onBlur={() => {
                  if (formErrors.question) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      question: "", // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.question && (
                <p className="error" style={{ color: "red" }}>
                  {formErrors.question}
                </p>
              )}
            </Box>
            <Box className="Type_Name">
              <InputLabel>Outcome 1</InputLabel>
              <input
                className="type_Input"
                name="outcome1"
                id="outcome1"
                value={market.outcome1}
                onChange={onChange}
                disabled={data ? true : false}
                placeholder="Enter outcome 1"
                onBlur={() => {
                  if (formErrors.outcome1) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      outcome1: "", // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.outcome1 && (
                <p className="error" style={{ color: "red" }}>
                  {formErrors.outcome1}
                </p>
              )}
            </Box>
            <Box className="Type_Name">
              <InputLabel>Outcome 2</InputLabel>
              <input
                className="type_Input"
                name="outcome2"
                id="outcome2"
                value={market.outcome2}
                onChange={onChange}
                disabled={data ? true : false}
                placeholder="Enter outcome 2"
                onBlur={() => {
                  if (formErrors.outcome2) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      outcome2: "", // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.outcome2 && (
                <p className="error" style={{ color: "red" }}>
                  {formErrors.outcome2}
                </p>
              )}
            </Box>
            <Box className="Type_Name">
              <InputLabel>Upload Image</InputLabel>
              <input
                type="file"
                name="image"
                id="image"
                accept=".jpg, .jpeg, .png, .webp, image/webp"
                onChange={saveFile}
              />
            </Box>
            <Box className="Type_Name">
              <InputLabel>Description</InputLabel>
              <TextareaAutosize
                className="description"
                name="description"
                id="description"
                value={market.description}
                onChange={onChange}
                minRows={3}
                maxRows={3}
                onBlur={() => {
                  if (formErrors.description) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      description: "", // Clear the error message for 'typeName'
                    }));
                  }
                }}
              />
              {formErrors.description && (
                <p className="error" style={{ color: "red" }}>
                  {formErrors.description}
                </p>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className="closeModal_Btn" onClick={closeModalHandle}>
            Close
          </Button>
          <Button
            className="closeModal_Btn"
            disabled={loading}
            onClick={submit}
          >
            {loading ? "loading" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default CreateModal;
