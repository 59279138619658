import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import contractInfo from "./contractInfo";
import Web3 from "web3";
import { ENV } from "../config";
export const NETWORK_CONFIG = {
  //For Mumbai test net
  5: {
    chainId: "0x5",
    chainName: "Goerli Testnet",
    rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://goerli.etherscan.io"],
    iconUrls: ["https://goerli.etherscan.io/favicon.ico"],
  },

  80001: {
    chainId: "0x13881",
    chainName: "Mumbai",
    nativeCurrency: {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
  //For Binance test net
  97: {
    chainId: "0x61",
    chainName: "Binance Smart Chain Testnet",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "tBNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  // Binance Smart Chain Mainnet
  56: {
    chainId: "0x38",
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  // Mumbai/Polygon Mainnet
  137: {
    chainId: "0x89",
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
};
// export const getWeb3 = () => {
//   const API_URL =
//     "https://eth-sepolia.g.alchemy.com/v2/k_CyfruNYQUhCCXJXB_K5bFAyhlji6ct";
//   const web3 = createAlchemyWeb3(API_URL);
//   return web3;
// };
export const getWeb3 = async () => {
  let instance;
  if (window?.ethereum) {
    await window.web3.currentProvider.enable();
    instance = new Web3(window.ethereum);
  } else {
    instance = new Web3("http://localhost:7545");
  }

  return instance;
};

export const getContractMethods = async () => {
  const web3 = await getWeb3();
  const { createMarketAbi, address } = contractInfo.createMarket;
  const methods = new web3.eth.Contract(createMarketAbi, address).methods;
  console.log(methods, "methods1==>>");
  return methods;
};
export const getContract = async () => {
  const web3 = await getWeb3();
  const { createMarketAbi, address } = contractInfo.createMarket;
  const contract = new web3.eth.Contract(createMarketAbi, address);
  return contract;
};

export const createWallet = async () => {
  const web3 = await getWeb3();
  const account = web3.eth.accounts.create(web3.utils.randomHex(32));
  const wallet = web3.eth.accounts.wallet.add(account);
  const keyStore = wallet.encrypt(web3.utils.randomHex(32));
  console.log(
    {
      account,
      wallet,
      keyStore,
    },
    "wallet creation"
  );
  return {
    account,
    wallet,
    keyStore,
  };
};

// createWallet();
export const connectWithMetamask = async () => {
  try {
    let web3 = await getWeb3();
    await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    let accounts = await web3.eth.getAccounts();
    return accounts[0];
  } catch (err) {
    // toast.error(err?.message);
    throw new Error(err);
  }
};
export const getMetamaskAddress = async () => {
  try {
    let web3 = await getWeb3();
    let accounts = await web3.eth.getAccounts();
    return accounts[0];
  } catch (err) {
    // toast.error(err?.message);
    throw new Error(err);
  }
};

export const switchNetworkToChainId = async (id) => {
  if (window?.ethereum) {
    try {
      let web3 = await getWeb3();
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(id) }],
      });
      let accounts = await web3.eth.getAccounts();
      return accounts[0];
    } catch (err) {
      if ([4902, -32603].includes(err.code)) {
        await addNetworkAutomatically(id);
        return;
      }
      // toast.error(err?.message || err);
      throw new Error(err);
    }
  }
};

export const getChainId = async () => {
  if (window?.ethereum) {
    let web3 = await getWeb3();
    let chainId = await web3.eth.getChainId();
    return chainId;
  }
};

export const handleSign = async (nounce) => {
  try {
    const web3 = new Web3(Web3.givenProvider);
    let accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    return new Promise((resolve, reject) =>
      web3.eth.personal.sign(
        web3.utils.fromUtf8(`I am signing my one-time nonce: ${nounce}`),
        address?.toLowerCase(),
        (err, signature) => {
          // setIsButtonDisabled(false);
          if (err) return reject(err);
          return resolve({ address, signature });
        }
      )
    );
  } catch (err) {
    // toast.error(err?.message);
    throw new Error(err);
  }
};
export const getMetamaskConnectedAddress = async () => {
  let web3 = await getWeb3();
  let accounts = await web3.eth.getAccounts();
  return accounts[0];
  ``````````````;
};

const addNetworkAutomatically = async (id) => {
  try {
    return await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [NETWORK_CONFIG[id]],
    });
  } catch (err) {
    // toast.error(err?.message);
    throw new Error(err);
  }
};
export const isMetaMaskInstalled = () => {
  if (
    // isMobile &&
    !window?.ethereum
  ) {
    window.open("https://metamask.app.link/dapp/drb-saudi-nft.invo.zone/");
    return false;
  }
  return typeof window.ethereum !== "undefined";
};
export const redirectToMetaMaskExtension = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("chrome") > -1) {
    window.location.href =
      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn";
  } else if (userAgent.indexOf("firefox") > -1) {
    window.location.href =
      "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/";
  } else if (userAgent.indexOf("safari") > -1) {
    window.location.href =
      "https://apps.apple.com/us/app/metamask/id1438144202";
  } else {
    // return toast.info("Metamask does not support this browser");
    // Handle other browsers or unsupported scenarios
    // Display a message or provide alternative instructions
  }
};
export const validateMetamask = async () => {
  try {
    const currentChainId = await getChainId();
    if (ENV.chainId !== currentChainId) {
      await switchNetworkToChainId(ENV.chainId );
    }
    let connectedAddress = await getMetamaskConnectedAddress();
    if (!connectedAddress) {
      connectedAddress = await connectWithMetamask();
    }
    if (!connectedAddress) {
      alert("Could not connect to Metamask");
      return null;
    }
    return connectedAddress;
  } catch (err) {
    console.log(err, "error=>");
  }
};
export const getTransactionByHash = async (
  hash = "0x90a282dfd54fc488411a63adb6e1be3862af0813e92c78a3b29f59eb10fa8e33"
) => {
  const contract = await getContract();
  const web3 = await getWeb3();
  const transaction = await web3.eth.getTransactionReceipt(hash);
  console.log(transaction, "transaction==>");
};
// getTransactionByHash()
