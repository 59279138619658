import React, { useState, useEffect, useContext } from "react";
import DashboardHeader from "../../components/DashboardHeader";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import UpdateModal from "../../components/Modal/UpdateModal";
import CreateModal from "../../components/Modal/CreateModal";
import { Box, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAxios, postAxios } from "../../utils/api";
import ResolveModal from "../../components/Modal/ResolveModal";

import { getProfit, resolveMarket } from "../../contracts/marketPlace/index";
import { useSelector, useDispatch } from "react-redux";
import { addTransaction } from "../../redux/actions/transaction";
import SocketContext from "../../context/socket";
import Loader from "../../components/Common/Loader";
import WithdrawProfitRow from "../../components/WithdrawProfit/WithdrawProfitRow";
// import TableData from "../../components/Table/TableData";
import Table from "../../components/Table";
function AnnounceWinner() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [isOpenResolveModal, setIsOpenResolveModal] = useState(false);
  const [marketModalData, setMarketModalData] = useState(null);
  const [isResolveMarketLoading, setIsResolveMarketLoading] = useState(false);
  const socket = useContext(SocketContext);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [slicedData, setSlicedData] = useState([]);
  const sliceDataForCurrentPage = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    return marketList?.slice(startIndex, endIndex);
  };
  useEffect(() => {
    const sliced = sliceDataForCurrentPage();
    // setSlicedData(sliced);
    formatData(sliced)
  }, [currentPage, marketList, recordsPerPage]);

  useEffect(() => {
    if (socket) {
      socket.on("MarketResolved", (res) => {
        console.log(res, "event received of resolved market==>");
        setIsResolveMarketLoading(false);
        fetchMarketList();
        setMarketModalData(null);
        setIsOpenResolveModal(false);
        if (res?.success) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
 
      });
    }
  }, [socket]);
  const openEmailModal = () => {
    setIsOpenResolveModal(true);
  };

  const closeEmailModal = () => {
    setIsOpenResolveModal(false);
  };

  const dispatch = useDispatch();
 
  useEffect(() => {
    const fetchData = async () => {
      await fetchMarketList();
      setPagination(calculateRange(marketList, recordsPerPage));
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    setPagination(calculateRange(marketList, recordsPerPage));
  }, [marketList]);
  const formatItem = (item, i) => {
    const { typeName, category, question, image, totalBets ,outcomes} =
      item;
    return [
      <span>{i + 1}</span>,
      <span>{typeName}</span>,
      <span>{category}</span>,
      <span>{question}</span>,
      <span>{"10%"}</span>,
      <span>${totalBets}</span>,


      <img
        src={image}
        className="dashboard-content-avatar"
        alt="Market Picture"
      />,
      <span>{`${outcomes[0]?.outcome1},${outcomes[1]?.outcome1}`}</span>,
      // <span>{winningOutCome}</span>,
      <Button
        variant="contained"
        // data-id={market?._id}
        size="small"
        onClick={() => {
          // setIsResolveMarketLoading(true)
          setMarketModalData(item);
          setIsOpenResolveModal(true);
          // handleResolveMarket(1, market.factoryContractAddress)
        }}
      >
        Resolve
      </Button>
    ];
  };

  const formatData = (data) => {
    const updatedData = data.map((item, i) => {
      return {
        data: formatItem(item, i),
      };
    });
    setSlicedData(updatedData);
  };

  const fetchMarketList = async () => {
    const expiredData = {
      url: "market/fetch_market_list?status=expired",
      payload: {},
    };
  
    // const resolvedData = {
    //   url: "market/fetch_market_list?status=resolved",
    //   payload: {},
    // };
  
    const [expiredResponse] = await Promise.all([
      getAxios(expiredData),
      // getAxios(resolvedData),
    ]);
  
    // Check for errors in both responses
    if (expiredResponse?.data?.error ) {
      setMarketList([]);
    } else {
      const updatedMarket = expiredResponse?.data?.data?.map((market) => {
    
        if(market?.bets?.length > 0){
        const totalBets =  market?.bets?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.amount, 0
          );
          return {...market, totalAmountOfBets: totalBets}
          // console.log('updateMarket ====>', updateMarket)
        }
        return market
      })
      // Concatenate the data from both responses
      setMarketList([
        ...(updatedMarket || []),
      ]);
    }
  };
  // Example usage:
  // fetchMarketList();

  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = slicedData?.filter(
        (item) =>
          item?.typeName?.toLowerCase()?.includes(search.toLowerCase()) ||
          item?.question?.toLowerCase()?.includes(search.toLowerCase()) ||
          item?.slug?.toLowerCase()?.includes(search.toLowerCase())
      );
      formatData(search_results)
      // setSlicedData(search_results);
    } else {
      resetSlicedData();
    }
  };

  const resetSlicedData = () => {
    formatData(sliceDataForCurrentPage())
    // setSlicedData(sliceDataForCurrentPage());
  };

  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setCurrentPage(new_page);
  };
  const handleResolveMarket = async (outcome, marketAddress, marketId) => {
    try {
      setIsResolveMarketLoading(true)
      openEmailModal(); // Start loading state
console.log(outcome, marketAddress, marketId,'id')
      // Perform the market resolution and get the result
      await resolveMarket({outcomeIndex:outcome.type, marketAddress},(txHash)=>handleTransactionResult(txHash, marketAddress, outcome, marketId));

      // Handle the transaction result
      // handleTransactionResult(txHash, marketAddress, outcomeIndex.type, marketId);
    } catch (error) {
      handleTransactionError(error);
    } finally {
      closeEmailModal(); // End loading state
    }
  };

// Function to handle the result of the market resolution
  const handleTransactionResult = (txHash, marketAddress, outcome, marketId) => {
    if (txHash) {
     const response= addNewTransaction({
        data: {
          marketAddress,
          winningOutCome: outcome,
        },
        txHash,
        id: marketId,
        status: "resolved",
      });
      console.log('response',response);
      toast.success("Wager resolved successfully");
    } else {
      toast.error("Transaction failed: Something went wrong");
    }
  };
  const handleTransactionError = (error) => {
    toast.error("Transaction failed: An unexpected error occurred");
    console.error("Unexpected error:", error);
  };
  const addNewTransaction = (data) => {
    dispatch(addTransaction(data));
  };
console.log(isResolveMarketLoading,'isResolveMarketLoading=>')
  return (
    <>
      {/* <Loader
        loading={isResolveMarketLoading}
        loadingText="Announcing Winner Please Wait...!"
      /> */}
      <div className="dashboard-content">
        <DashboardHeader />
        <div className="dashboard-content-container">
          <div className="dashboard-content-header">
            <h2>Announce Winner</h2>
            <div className="dashboard-content-search">
              <input
                type="text"
                value={search}
                placeholder="Search.."
                className="dashboard-content-input"
                onChange={(e) => __handleSearch(e)}
              />
            </div>
          </div>
          <Table
          titles={[
            "ID",
            "TYPE NAME",
            "CATEGORY",
            "QUESTION",
            "PROFIT%",
            "MARKET TOTAL",
            "IMAGE",
            "OUTCOMES",
            "ACTIONS",
          ]}
          data={slicedData}
          pagination={{ currentPage, recordsPerPage }}
        />
          {slicedData?.length > 0 ? (
            <div className="dashboard-content-footer">
              {pagination.map((item, index) => (
                <span
                  key={index}
                  className={item === page ? "active-pagination" : "pagination"}
                  onClick={() => __handleChangePage(item)}
                >
                  {item}
                </span>
              ))}
            </div>
          ) : (
            <div className="dashboard-content-footer">
              <span className="empty-table">No data</span>
            </div>
          )}
        </div>
        {marketModalData && (
          <ResolveModal
            openEmail={isOpenResolveModal}
            openEmailModal={openEmailModal}
            closeEmailModal={closeEmailModal}
            data={marketModalData}
            handleResolveMarket={handleResolveMarket}
            setIsResolveMarketLoading={setIsResolveMarketLoading}
            isResolveMarketLoading={isResolveMarketLoading}
          />
        )}
      </div>
    </>
  );
}

export default AnnounceWinner;
