import { getContractMethods, getWeb3 } from "../web3";
import { getMetamaskAddress } from "../web3";
const createMarket = async (
{  endTime ,
  startTime,
  handleCreateNewMarket}
) => {
  try {
    const web3 = await getWeb3();
    console.log(web3,"web3=>")
    const methods = await getContractMethods();
    const address = await getMetamaskAddress();
    const createMarketTx = await methods
      .createMarket(startTime,endTime)
      .send({
        from: address,
      })
      .on("transactionHash", function (hash) {
        handleCreateNewMarket(hash, address, 
        );
      }).on("receipt", function (receipt) {
     //complete
      });

    return {
      success: true,
      factoryContractAddress:
        createMarketTx.events?.MarketCreated?.returnValues.marketAddress
    };
  } catch (err) {
    console.log(err, "error==>");
    // return {
    //   success: false,
    //   err,
    // };
    throw new Error(err?.message)
  }
};

export { createMarket };
