import axios from "axios";
// import {SERVER_ADDRESS} from "./constants";
import { ENV } from "../config";
const getConfigs = () => {
    const token = localStorage.getItem("token")
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
}

export const getAxios = async (data, headers = {}) => {
    try {
      // Construct the URL
      const url = `${ENV.serverUrl}/${data.url}`;
      
      // Get configurations with optional headers
      const configs = getConfigs(headers);
      
      // Make the GET request with query parameters
      const response = await axios.get(url, {
        params: data.payload, // Use `params` for query parameters
        ...configs          // Spread in the configuration
      });
  
      return response;
    } catch (error) {
      // Handle error (logging or rethrowing)
      console.error('Error in GET request:', error);
      throw error;
    }
  };

export const postAxios = async (data) => {
    return await axios.post(`${ENV.serverUrl}/${data.url}`,data.payload, getConfigs());
}

export const deleteAxios = async (data) => {
    return await axios.delete(`${ENV.serverUrl}/${data.url}`,data.payload, getConfigs());
}
