import axios from "axios";
// import { ENV } from "../../config/config";
import { toast } from "react-toastify";
const handleError = (err) => {
  // if (error.response.status === 401) {
  //   const a = document.createElement("a");
  //   a.href = "/logout";
  //   a.click();
  // }
  // if (error.response.status >= 400 && error.response.status < 500) {
  //   if (typeof error.response.data.message === "object") {
  //     throw new Error(Object.values(error.response.data.message)[0]);
  //   } else if (Array.isArray(error.response.data.message)) {
  //     throw new Error(error.response.data.message[0]);
  //   } else if (typeof error.response.data.message === "string") {
  //     throw new Error(error.response.data.message);
  //   }
  // }
  // throw new Error("Something went wrong!");
  if (err?.response) {
    // console.log(err.response,err?.response?.data?.message, "response==>");
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const errorMessage = err?.response?.data?.message || "An error occurred";
    console.log(errorMessage, "errorMessage=>");
    toast.error(errorMessage);
  } else if (err.request) {
    // The request was made but no response was received
    toast.error("No response received from the server");
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error("Error occurred while making the request");
  }
};
// const getHeaders=()=>{
//   const baseHeaders = {
//     Authorization: `Bearer ${ENV.getUserKeys("accessToken").accessToken}`,
//   };
//   return baseHeaders
// }
// Helper function to get the access token from localStorage
const getAccessToken = () => localStorage.getItem('accessToken');
export const postRequest = (url, formData, headers = {}) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return axios
    .post(url, formData,{ headers })
    .then((response) => response.data)
    .catch((error) => handleError(error));
};

export const putRequest = (url, formData, headers = {}) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return axios
    .put(url, formData,{ headers })
    .then((response) => response.data)
    .catch((error) => handleError(error));
};

export const patchRequest = (url, formData,headers = {}) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return axios
    .patch(url, formData,{ headers })
    .then((response) => response.data)
    .catch((error) => handleError(error));
};

export const getRequest = (url, params,headers = {}) => {
  //   console.log(ENV.getUserKeys("accessToken").accessToken, "token==>");
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return axios
    .get(url, { params,headers })
    .then((response) => response.data)
    .catch((error) => handleError(error));
};

export const deleteRequest = (url, data,headers = {}) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return axios
    .delete(url, { data,headers })
    .then((response) => response.data)
    .catch((error) => handleError(error));
};

export const recoverRequest = (url, data) => {
  return axios
    .post(url, { data })
    .then((response) => response.data)
    .catch((error) => handleError(error));
};
